





























































import {
  Component, Vue, Prop, Provide,
} from 'vue-property-decorator';
import PieTitle from './PieTitle.vue';
import PieStatus from './PieStatus.vue';
import PiePrice from './PiePrice.vue';
import PieTimer from './PieTimer.vue';
import { Status, PieInterface } from '../config';

@Component({
  components: {
    PieTitle,
    PieStatus,
    PiePrice,
    PieTimer,
  },
})
export default class Pie extends Vue {
    @Prop() pie!: PieInterface;

    @Provide() title!: string;

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }

    get isOff(): boolean {
      return this.pie.status === Status.OFF;
    }

    get isSoldOut(): boolean {
      return this.pie.status === Status.SOLD_OUT;
    }

    get hasTimer(): boolean {
      return !!this.pie.timer;
    }

    get isOn(): boolean {
      return this.pie.status === Status.ON;
    }
}
