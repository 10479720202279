





























import {
  Component, Vue, Prop, Ref, Provide,
} from 'vue-property-decorator';
import Pie from '@/components/Pie.vue';
import {
  Status, PieGroupInterface, PieInterface, TitleInterface,
} from '@/config';

@Component({
  components: {
    Pie,
  },
})
export default class PieGroup extends Vue {
    @Prop() private auth!: boolean

    @Prop() private group!: PieGroupInterface

    @Provide() editing = false;

    @Provide() value = '';

    @Ref('title') readonly refTitle!: HTMLInputElement;

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }

    startEditing() {
      this.value = this.group.title;
      this.editing = true;
      this.$nextTick(() => this.refTitle.focus());
    }

    stopEditing() {
      if (this.editing && this.value
          && this.value !== this.group.title) {
        const payload: TitleInterface = {
          id: this.group.docid,
          title: this.value,
        };

        this.$store.dispatch('saveGroupTitle', payload)
          .then(() => this.$bvToast.toast('Successfully updated menu title.', {
            variant: 'success',
            noCloseButton: true,
          }));
      }

      this.editing = false;
    }

    get pies() {
      return this.$store.getters.getPiesByGroupId(this.group.id);
    }

    showPie(pie: PieInterface): boolean {
      return this.auth || (pie.status !== Status.OFF);
    }
}
