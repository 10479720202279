import firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp({
  projectId: `${process.env.VUE_APP_PROJECTID}`,
});

export const db = firebase.firestore();

if (window.location.hostname === 'localhost') {
  db.settings({
    host: 'localhost:8080',
    ssl: false,
  });
}

export default {};
