






























import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import Pie from './Pie.vue';
import PieGroup from './PieGroup.vue';

@Component({
  components: {
    Pie,
    PieGroup,
  },
})

export default class GroupList extends Vue {
    @Prop({ default: false }) private auth!: boolean;

    get groups() {
      return this.$store.state.groups;
    }

    get firstGroup() {
      return this.$store.getters.getGroupByColumn(1);
    }

    get secondGroup() {
      return this.$store.getters.getGroupByColumn(2);
    }

    get thirdGroup() {
      return this.$store.getters.getGroupByColumn(3);
    }
}
