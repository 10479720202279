import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import store from '@/store';
import App from './App.vue';
import router from './router';
import './assets/scss/app.scss';

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    this.$store.dispatch('fetchGroups');
    this.$store.dispatch('fetchPies');
  },
}).$mount('#app');
