import Vue from 'vue';
import Vuex from 'vuex';
import { db } from './firebase';
import {
  Status, PieInterface, PieGroupInterface, PieStatus, PieTimer, TitleInterface,
} from './config';

Vue.use(Vuex);

const defaultPieGroup: PieGroupInterface = {
  id: 0,
  docid: '',
  title: 'New Menu',
  order: 999,
  column: 1,
};

export default new Vuex.Store({
  state: {
    pies: Array<PieInterface>(),
    groups: Array<PieGroupInterface>(),
  },

  getters: {
    getPiesByGroupId: (state) => (id: number) => state.pies
      .filter((pie) => pie.group === id),

    getGroupByColumn: (state) => (column: number) => state.groups
      .filter((group) => group.column % 4 === column),
  },

  mutations: {
    addGroup(state, group: PieGroupInterface) {
      return state.groups.push({ ...defaultPieGroup, ...group });
    },

    updateGroup(state, group: PieGroupInterface) {
      const index = state.groups.findIndex((existing) => existing.id === group.id);
      state.groups[index].title = group.title;
    },

    removeGroup(state, group: PieGroupInterface): void {
      const existingIndex = state.groups.findIndex((existing) => existing.id === group.id);
      state.groups.splice(existingIndex, 1);
    },

    addPie(state, pie: PieInterface) {
      state.pies.push(pie);
    },

    updatePie(state, pie: PieInterface) {
      const index = state.pies.findIndex((existing) => existing.docid === pie.docid);
      state.pies[index].title = pie.title;
      state.pies[index].status = pie.status;
      state.pies[index].timer = pie.timer;
      state.pies[index].deadline = pie.deadline;
    },

    removePie(state, pie: PieInterface): void {
      const existingIndex = state.pies.findIndex((existing) => existing.id === pie.id);
      state.pies.splice(existingIndex, 1);
    },

  },

  actions: {
    fetchGroups(context) {
      const collection = db.collection('groups').orderBy('order');

      collection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => context.dispatch('onGroupChanges', change));
      }, (err) => {
        console.log(`Encountered error: ${err}`);
      });
    },

    onGroupChanges(context, change: firebase.firestore.DocumentChange) {
      const data = change.doc.data();

      const group: PieGroupInterface = {
        docid: change.doc.id,
        id: data.id,
        title: data.title,
        order: data.order,
        column: data.column,
      };

      if (change.type === 'added') {
        console.log(`Vuex received ${group.title}.`);
        this.commit('addGroup', group);
      } else if (change.type === 'modified') {
        this.commit('updateGroup', group);
        console.log(`Vuex received ${group.title} update.`, group);
      } else if (change.type === 'removed') {
        console.log(`Vuex received ${group.title} removal.`, group);
        this.commit('removeGroup', group);
      }
    },

    addGroup(context, group: PieGroupInterface) {
      context.commit('addGroup', group);
    },

    updateGroup(context, group: PieGroupInterface) {
      context.commit('updateGroup', group);
    },

    removeGroup(context, group: PieGroupInterface) {
      context.commit('removeGroup', group);
    },

    fetchPies(context) {
      const collection = db.collection('pies').orderBy('order');

      collection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => context.dispatch('onPieChanges', change));
      }, (err) => {
        console.log(`Encountered error: ${err}`);
      });
    },

    onPieChanges(context, change: firebase.firestore.DocumentChange) {
      const data = change.doc.data();

      const pie: PieInterface = {
        docid: change.doc.id,
        title: data.title,
        price: data.price,
        status: data.status,
        group: data.group,
        order: data.order,
        timer: data.timer,
        deadline: data.deadline,
      };

      if (change.type === 'added') {
        console.log(`Vuex received ${pie.title}.`);
        this.commit('addPie', pie);
      } else if (change.type === 'modified') {
        this.commit('updatePie', pie);
        console.log(`Vuex received ${pie.title} update.`, pie);
      } else if (change.type === 'removed') {
        console.log(`Vuex received ${pie.title} removal.`, pie);
        this.commit('removePie', pie);
      }
    },

    addPie(context, pie: PieInterface) {
      db.collection('pies')
        .add(pie);
    },

    savePie(context, pie: PieInterface) {
      db.collection('pies')
        .doc(pie.docid)
        .update(pie);
    },

    savePieStatus(context, payload: PieStatus) {
      db.collection('pies')
        .doc(payload.id)
        .update({ status: payload.status, timer: null, deadline: null });
    },

    startPieTimer(context, payload: PieTimer) {
      db.collection('pies')
        .doc(payload.id)
        .update({
          timer: payload.timer,
          deadline: payload.deadline,
        });
    },

    updatePieTimer(context, payload: PieTimer) {
      db.collection('pies')
        .doc(payload.id)
        .update({
          timer: payload.timer ? payload.timer : null,
        });
    },

    stopPieTimer(context, id: string) {
      db.collection('pies')
        .doc(id)
        .update({
          timer: null,
          deadline: null,
        });
    },

    saveGroupTitle(context, payload: TitleInterface) {
      db.collection('groups')
        .doc(payload.id)
        .update({ title: payload.title });
    },

    completedPieTimer(context, id: string) {
      db.collection('pies')
        .doc(id)
        .update({
          status: Status.ON,
          timer: null,
          deadline: null,
        });
    },

    deletePie(context, pie: PieInterface) {
      db.collection('pies')
        .doc(pie.docid)
        .delete();
    },

  },
});
