


























import {
  Component, Vue, Provide, Ref, Prop,
} from 'vue-property-decorator';
import { db } from '../firebase';

@Component

export default class PieTitle extends Vue {
    @Prop() id!: string;

    @Prop() title!: string;

    @Provide() editing = false;

    @Provide() value = '';

    @Ref('title') readonly refTitle!: HTMLInputElement;

    startEditing() {
      this.value = this.title;
      this.editing = true;
      this.$nextTick(() => this.refTitle.focus());
    }

    stopEditing() {
      const title: string = this.value;

      if (this.value && this.value !== this.title) {
        db.collection('pies')
          .doc(this.id)
          .update({ title })
          .then(() => {
            this.$bvToast.toast('Successfully updated title.', {
              variant: 'success',
              noCloseButton: true,
            });
          });
      }

      this.editing = false;
    }

    get isAdmin(): boolean {
      return this.$route.path === '/admin';
    }
}
